
.home-page {
  background-image: url('../../images/Fondo\ 02.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: black;
  background-size: cover;
  margin: 0;
  min-height: 100vh; 
  width: 100%;  
}

.BotonCerrarSesion {
    font-family: 'Roboto', sans-serif;
    background-color: #000000;
    border: 2px solid #52df8c;
    padding: 5px 5px 5px 5px;
    text-decoration: none;
    flex-direction: row;
    align-items: baseline;
    cursor: pointer;
    text-align: center;
    border-radius: 7px;
    width: 100px;
    position: absolute;
    top: 15px;
    right: 10px;
    color: #ffffff
}

.BotonCerrarSesion:hover {
    background-color: #22a357;
    color: #ffffff;
}

h1 {
    font-family: 'Roboto', sans-serif;
    color: #9EAFA2;
    font-size: 3rem;
    padding: 0%;
    margin: 0;
    text-align: center;
}
  .nameUser {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-size: 25px;
    text-align: center;
    margin: 0px;
    padding: 10px 5px 10px 0px;
    font-style: bold;
  }

  .nameUserContainer {
    font-style: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    position: relative;
  }

  .isologo {
    display: flex;
    width: 45px;
    height: 45px;
    margin: 2px;
    padding: 2px;
    top: 5px;
    left: 5px;
    position: absolute;
  }