@keyframes errorAnimation {
    0% { opacity: 0; transform: translateY(-20px); }
    100% { opacity: 1; transform: translateY(0); }
}

.error {
    background-color: rgba(0, 0, 0, 0.4); /* Fondo rojo */
    border: groove 1px #ff0000;
    border-radius: 5px;
    color: #ff0000; /* Texto blanco */
    padding: 10px; /* Relleno */
    width: 70%; /* Ancho completo */
    text-align: center; /* Centrar texto */
    animation: errorAnimation 0.5s ease; /* Animación */
    font-size: 14px; /* Tamaño de fuente */
    font-weight: bold; /* Fuente en negrita */
    font-family: 'Roboto', sans-serif;
}