.inputBox {
    position: relative;
    width: 250px;
}

.inputBox input {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding: 5px 0;
    padding-left: 5px; /* Ajusta el valor de padding-left para mover el texto a la derecha */
    font-size: 16px;
    border: 1px solid rgba(255, 255, 255);
    border-radius: 5px;
    outline: none;
    background: transparent;
    color: #ffffff;
    font-size: 1em;
    letter-spacing: 1px;
    transition: 0.5s;
}

.inputBox span {
    font-family: 'Roboto', sans-serif;
    position: absolute;
    left: 0;
    margin: -30px 0 0 5px;
    font-size: 14px;
    pointer-events: none;
    color: rgba(255, 255, 255);
    text-transform: uppercase;
    transition: 0.5s;
}

.inputBox input:valid ~ span,
.inputBox input:focus ~ span {
    color: #22a357;
    font-size: 13px;
    margin: -50px 0 0 0px;
    padding: 0px 10px 0px 10px;
    transform: translateX(10px);
    background: #000000;
    border-left: 1px solid #22a357;
    border-right: 1px solid #22a357;
    letter-spacing: 0.2em;
}

.inputBox input:valid ~ span,
.inputBox input:focus ~ span {
background: #22a357;
color: #ffffff;
border-radius: 4px;
}

.inputBox input:valid,
.inputBox input:focus {
border: 1px solid #22a357;
}


.formlogin { 
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 20px 50px 20px;
    transform: translate(-50% -50%);
    background: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    box-shadow: 0px 10px 50px 10px rgba(0, 0, 0, 0.8);
    border-radius: 10px;
}

.boton-inicio {
    font-family: 'Roboto', sans-serif;
    background-color: rgba(0, 0, 0,0.6);
    color: #ffffff;
    border: solid 1px #ffffff;
    font-size: 14px;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    margin: 0px 100px;
    cursor: pointer;
    text-align: center;
    border-radius: 7px;
    width: 150px;
}

.boton-inicio:hover {
    background-color: #22a357;
    color: #000000;
    border: solid 1px #000000;
    padding: 10px 10px 10px 10px;
    transition: 0.5s;
}

.logo {
    width: 250px; /* Ajusta esto a la anchura que desees para tu imagen */
    height: 100%; /* Ajusta esto a la altura que desees para tu imagen */
    margin: 0px 0px 20px 0px; /* Ajusta el valor de margin-top para subir la posición del logo */
    
}

