.reports-page {
    background-image: url('../../images/Fondo\ 02.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: black;
    background-size: cover;
    margin: 0;
    min-height: 100vh; 
    width: 100%;  
}
.modal-dates label {
    color: white;
    font-size: 12px;
    align-items: first baseline;
    display: flex;
    margin-right: -30px; 
}
.navbar {
    /* Asegúrate de que este valor sea mayor que el del modal */
      height: 70px; 
  }
  
  .Modal__overlay {
    z-index: 999; /* Asegúrate de que este valor sea menor que el de la barra de navegación */
  }

.Modal__content  {
    align-content: space-around;
    color: rgb(255, 255, 255); /* Cambia el color del texto a blanco */
    width: 50%; /* Ajusta el ancho del modal al 80% del ancho de la ventana */
    height: 110%; /* Ajusta la altura del modal al 80% de la altura de la ventana */
    align-items: center;

}

.modal-dates {
    display: flex; /* Alinea los inputs de fecha uno al lado del otro */
    justify-content: space-between; /* Añade espacio entre los inputs */
    background-color: black;
    
}

.modal-buttons {
    display: flex; /* Alinea los botones uno al lado del otro */
    justify-content: space-around; /* Añade espacio entre los botones */
}
button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

.modal-input {
    color: rgb(255, 255, 255); /* Cambia el color del texto de los inputs a blanco */
    background-color: rgba(48, 46, 46, 0.8); /* Cambia el color de fondo de los inputs a un gris oscuro */
    border: 1px solid #22a357; /* Añade un borde alrededor de los inputs */
    border-radius: 7px;
    justify-content: center;
    margin: 0px 40px 20px 40px; /* Añade margen a los inputs */
    text-align: center; /* Centra el texto dentro de los inputs */
}

.modal-button {
    color: rgb(255, 255, 255); /* Cambia el color del texto de los botones a blanco */
    background-color: rgba(0, 0, 0, 0.8); /* Cambia el color de fondo de los botones a un gris medio */
    border: 1px solid #22a357; /* Elimina el borde de los botones */
    border-radius: 7px;
    padding: 10px 30px; /* Añade padding a los botones */
    text-align: center; /* Centra el texto dentro de los botones */
    text-decoration: none; /* Elimina la decoración del texto de los botones */
    display: inline-block; /* Hace que los botones sean inline-block */
    justify-content: space-around; /* Centra el contenido de los botones */
    font-size: 16px; /* Cambia el tamaño de la fuente de los botones */
    margin: 0px 10px 20px; /* Añade margen a los botones */
    cursor: pointer; /* Cambia el cursor a pointer cuando se pasa por encima de los botones */
}

.btnsReportes {
    display: flex; /* Alinea los botones uno al lado del otro */
    justify-content: space-between; /* Añade espacio entre los botones */
}

.modalCompleto {
    align-items: center;
}
 .modal-select {
    color: rgb(255, 255, 255); /* Cambia el color del texto de los selects a blanco */
    justify-content: center;
    display: flex; /* Alinea los selects uno al lado del otro */
 }

 .modal-buttons {
    display: flex; /* Alinea los botones uno al lado del otro */
    justify-content: space-between; /* Añade espacio entre los botones */
 }
 .open-modal-button{
    color: rgb(255, 255, 255); /* Cambia el color del texto de los botones a blanco */
    background-color: rgba(0, 0, 0, 0.8); /* Cambia el color de fondo de los botones a un gris medio */
    border: 1px solid #22a357; /* Elimina el borde de los botones */
    border-radius: 7px;
    padding: 10px 30px; /* Añade padding a los botones */
    text-align: center; /* Centra el texto dentro de los botones */
    text-decoration: none; /* Elimina la decoración del texto de los botones */
    display: inline-block; /* Hace que los botones sean inline-block */
    justify-content: space-around; /* Centra el contenido de los botones */
    font-size: 16px; /* Cambia el tamaño de la fuente de los botones */
    margin: 0px 10px 50px; /* Añade margen a los botones */
    cursor: pointer; /* Cambia el cursor a pointer cuando se pasa por encima de los botones */
 }

 @media (max-width: 500px) {
    .Modal__content {
      width: 90%;
      height: 150%;
    }
  
    .modal-dates, .modal-buttons, .btnsReportes, .modal-select {
      flex-direction: column;
    }
  
    .modal-input, .modal-button, .open-modal-button {
      margin: 10px 0;
    }
  
   
  }