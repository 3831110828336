.cardsemana {
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
    margin: 0%;
    padding: 10px 0px 0px 0px;
    width: 100%;
    height: 100%;    
    justify-content: space-around;
}

@media screen and (max-width: 768px) {
    .cardsemana {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        position: relative;
    }
    
}

@media (min-width: 768px) and (max-width: 1080px) {
    .cardsemana {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: center;
        position: relative;
    }
  }

.btnSemanaIzq {
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: 8px 0px 0px 8px;
    color: #ffffff;
    margin: 0px; /* Updated */
    justify-content: center;
}
.btnSemanaDer {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #ffffff;
    margin: 0px; /* Updated */
    justify-content: center;
}
.btnSemana {
    flex-direction: row;
    padding: 10px 0px 10px 0px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-content: flex-start;

}
.textSemana {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    margin: 0px;
    padding: 10px 10px 10px 10px;
}

.flechaDer {
    transform: rotate(-90deg);
}

.flechaIzq {
    transform: rotate(90deg);
}