.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  background-color: #080707;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: fixed; /* Fija la navbar en la parte superior de la página */
  top: 0; /* Alinea la navbar en la parte superior de la ventana del navegador */
  left: 0; /* Alinea la navbar al lado izquierdo de la ventana del navegador */
  z-index: 1000; /* Asegura que la navbar se muestre por encima de otros elementos de la página */
}
.logo-container {
  display: flex;
  align-items: center; /* Centra el logo verticalmente */
  justify-content: center; /* Centra el logo horizontalmente */
  height: 100%; /* Asegura que el contenedor del logo tenga la misma altura que la navbar */
}

.isologo {
  display: flex;
  width: 45px;
  height: 45px;
  margin: 2px;
  padding: 2px;
  top: 5px;
  left: -15px;
  position: relative;
}
.nav-button {
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.nav-button:hover {
  color: #22a357;
}

.nav-button.active {
  color: #000;
}
  
  .nav-links {
    font-family: 'Roboto', sans-serif;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  
  .nav-links li {
    box-sizing: border-box;
    margin-right: 10px;
}
  .nav-links a {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
  }
  
  .nav-links a:hover {
    color: #22a357;
  }
  
  .nav-links.active a {
    color: #ffffff;
  }
  
  .menu-toggle {
    display: none;
    cursor: pointer;
  
   
  }
  
  .menu-icon {
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    span {
      width: 100%;
      height: 2px;
      background-color: #333;
      transition: all 0.3s ease-in-out;
    }
  }
  
  .menu-icon span:nth-child(1) {
    margin-top: 0;
  }
  
  .menu-icon span:nth-child(2) {
    margin: 4px 0;
  }
  
  .menu-icon span:nth-child(3) {
    margin-bottom: 0;
  }
  
  .menu-icon.active span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 10px);
  }
  
  .menu-icon.active span:nth-child(2) {
    opacity: 0;
  }
  
  .menu-icon.active span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -10px);
  }
  

  .user-info {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-size: 25px;
    text-align: center;
    margin: 0px;
    padding: 10px 5px 10px 0px;
    font-style: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-right: 10px;
  
  
}

.user-info div {
  font-style: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    position: relative;
    margin-right: 10px;
}
.navbar-container {
  height: 100px; /* Asegura que la barra de navegación tenga un tamaño fijo */
}

/* Cuando la pantalla es de 768px o menos, centramos el contenido y eliminamos el margen derecho */
@media (max-width: 768px) {
  .user-info {
    position: fixed; /* Mantiene la posición fija */
    top: 0; /* Ajusta al inicio de la ventana para que se mantenga en la parte superior */
    left: 0; /* Alinea al lado izquierdo */
    width: 100%; /* Mantiene el ancho al 100% */
    height: 60px; /* Ajusta la altura para que encaje dentro de la barra de navegación */
    justify-content: center; /* Centra los elementos horizontalmente */
    z-index: 1001; /* Asegura que el menú se muestre por encima de otros elementos */
  }
  .nav-links {
    position: absolute; /* Makes the element positioned relative to its nearest containing positionned ancestor */
    top: 256%; /* Positions the top edge of the element 100% below its containing element */
    left: 70%; /* Positions the left edge of the element flush with the left edge of its containing element */
    width: 100%;
    background-color: #080707; /* Set background color to match navbar */
    z-index: 10; /* Sets the stacking order of the element */
    padding: 20px; /* Adds padding to the element */
    flex-direction: column; /* Arranges the navigation links vertically */
    align-items: flex-start; /* Aligns the navigation links to the left edge of the container */
    transition: all 0.3s ease-in-out; /* Defines a smooth transition between states */
    transform: translateY(-100%); /* Moves the element upwards by 100% of its own height */
  }
  .nav-links.active {
    display: flex;
  }

  .nav-links {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    margin-right: 0px;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
  }
  
  .nav-links a:hover {
    color: #22a357; /* Cambia el color al hacer hover */
  }

  .nav-links.active a {
    color: #fbf7f7; /* Color negro para el enlace activo */
  }
   /* Asegúrate de que el hover se aplique incluso cuando .nav-links tenga la clase .active */
   .nav-links.active a:hover {
    color: #22a357; /* Esto garantiza que el hover tenga prioridad */
  }

  .nav-button {
    text-decoration: none;
    color: #ffffff; /* Color blanco para el botón */
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    background: none;
    border: none;
    padding: 4px 0px; /* Ajusta el espaciado interno del botón */
    border-radius: 5px; /* Añade bordes redondeados según sea necesario */
    cursor: pointer;
  }

  .nav-button:hover {
    color: #22a357; /* Color verde cuando se hace hover */
  }

  .nav-button.active {
    color: #ffffff; /* Mantén el color blanco para el botón activo */
  }

  .menu-toggle {
    display: block;
    position: absolute;
    top: 30px;
    right: 20px;
    z-index: 1001;
  }
  
}
