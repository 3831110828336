.Modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
}

.Modal__content {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 100px 5px rgba(34, 163, 87, 0.2);
  padding: 10px 100px 10px 100px; 
  max-width: 500px ;
  max-height: 60vh ;
  overflow: auto;
  border-radius: 8px;
  z-index: 1001;
  position: relative;
  margin: auto;
}

.Modal__overlay:hover {
  border: none;
}