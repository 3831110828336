.bntCargar {
    font-family: 'Roboto', sans-serif;
    background-color: #000000;
    border: 2px solid rgba(82, 223, 140, 0.6);
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
    margin: 30px 10px 10px 10px;
    font-size: 12px;
} 
.bntCargar:hover {
    background-color: #22a357;
    color: #ffffff;
}
.bntCargar:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.bntCargara:focus {
    outline: none;
}

.formCarga {
    font-family: 'Roboto', sans-serif;
   align-items: center;
    justify-content: center;
}

.btnCloseCarga {
    cursor: pointer;
    background-color: transparent;
    border: none;
    background-position: right;
    position: absolute;
    top: 10px;
    right: 20px;
    width: 20px; /* Reduce the width of the image */
    height: 20px; /* Reduce the height of the image */
}

.bntAct {
    font-family: 'Roboto', sans-serif;
    background-color: #000000;
    border: 1px solid rgba(82, 223, 140, 0.6);
    color: #ffffff;
    cursor: pointer;
    padding: 5px 5px 5px 5px;
    margin: 10px 10px;
    text-align: center;
    border-radius: 7px;
    font-size: 12px;
}
select {
    font-family: 'Roboto', sans-serif;
    background: transparent;
    color: rgba(255, 255, 255);
    outline: none;
    border: 0px;
    border-bottom: 1px solid #52df8c;
    font-size: 14px;
    gap: 10px;
    height: 30px;
    padding: 5px;
    width: 250px;
    font-size: 12px;
    letter-spacing: 1px;
    transition: 0.5s;
 }

 select option {
    background-color: #000000;
    color: #ffffff; 
  }

 input {
    font-family: 'Roboto', sans-serif;
    background: transparent;
    border: 0px;
    border-bottom: 1px solid #52df8c;
    font-size: 14px;
    height: 30px;
    padding: 5px;
    width: 240px;
    gap: 10px;
    color: #ffffff;
    outline:none;
}
.swal-overlay {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .alertaEliminar {
    font-family: 'Roboto', sans-serif;
    background-color: #f7f9f8;
    border: 1px solid #52df8c;
    color: #ffffff;
    text-align: center;
    border-radius: 7px;
    font-size: 10px;
  
  }
  
  .alertaBtn1 {
    font-family: 'Roboto', sans-serif;
    background-color: #000000;
    color: #22a357;
    border: 1px #000000;
  }
  
  .alertaBtn2 {
    font-family: 'Roboto', sans-serif;
    background-color: #000000;
    color: #ffffff;
  }
  
  .alertaBtn1:hover {
    background-color: #000000;
    color: #000000;
  }
  
  .alertaBtn2:hover {
    background-color: #000000;
    color: #000000;
  }
  
  .separador {
  color: #ffffff;
  }
  