.carddia {
  font-family: 'Roboto', sans-serif;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: groove 1px #22a357;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.5);
    padding: 20px 10px 10px 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    text-align: center;
    text-decoration: #9EAFA2;
}

@media screen and (max-width: 768px) {
    .carddia {
        display: grid;
        justify-content: space-around;
        align-content: space-around;
        margin: 0%;
        padding: 0px;
        justify-items: center;
    }
}

@media (min-width: 768px) and (max-width: 1080px) {
    .carddia {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 10px;
        margin: 10px;
        justify-content: space-around;
        align-content: space-around;
        margin: 0%;
        padding: 0px;
    }
    .BotonAgregarHora {
      font-family: 'Roboto', sans-serif;
        width: 100px;
    }
  }

h2 {
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    margin: 0px;
}

p {
    color: #9EAFA2;
    font-size: 13px;
}

.BotonAgregarHora {
  font-family: 'Roboto', sans-serif;
    background-color: #22a357;
    flex-direction: row;
    border: 2px solid #52df8c;
    padding: 5px 5px 5px 5px;
    color: #ffffff;
    margin: 0px;
    cursor: pointer;
    text-align: center;
    border-radius: 7px;
    width: 110px;
    position: relative;
}

.BotonAgregarHora:hover {
    background-color: #000000;
    color: #ffffff;
}

.BotonAgregarHora:focus {
    outline: none;
}  
  .close {
    font-family: 'Roboto', sans-serif;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .btnElim {
    font-family: 'Roboto', sans-serif;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid #52df8c;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    border-radius: 7px;
    font-size: 10px;
  }

  .btnEdit {
    font-family: 'Roboto', sans-serif;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid #52df8c;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    border-radius: 7px;
    font-size: 10px;
}

.swal-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.alertaEliminar {
  font-family: 'Roboto', sans-serif;
  background-color: #22a357;
  border: 1px solid #52df8c;
  color: #ffffff;
  text-align: center;
  border-radius: 7px;
  font-size: 10px;

}

.alertaBtn1 {
  font-family: 'Roboto', sans-serif;
  background-color: #000000;
  color: #ffffff;
  border: 1px #000000;
}

.alertaBtn2 {
  font-family: 'Roboto', sans-serif;
  background-color: #000000;
  color: #ffffff;
}

.alertaBtn1:hover {
  background-color: #000000;
  color: #000000;
}

.alertaBtn2:hover {
  background-color: #000000;
  color: #000000;
}

.separador {
color: #ffffff;
}
